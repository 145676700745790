import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';

import { GeneralSelectors, ModalType } from '@/store/General';

import ModalResults from './ModalResults';

const Setting = dynamic(() => import('@/app/_components/ModalConfig/Setting'), {
	ssr: false,
});

const GuideModal = dynamic(
	() => import('@/app/_components/Common/GuideModal'),
	{
		ssr: false,
	}
);

const ThemeSetting = dynamic(
	() => import('@/app/_components/ModalConfig/ThemeSetting'),
	{
		ssr: false,
	}
);
const CreateEvent = dynamic(
	() => import('../../[locale]/account/events/event-new'),
	{
		ssr: false,
	}
);

const ListEvent = dynamic(() => import('@/app/_components/ListEvent'), {
	ssr: false,
});

const ListOption = dynamic(
	() => import('@/app/[locale]/home/ListParticipant'),
	{
		ssr: false,
	}
);

const ContactModal = dynamic(() => import('@/app/_components/ContactModal'), {
	ssr: false,
});

const SendEmailModal = dynamic(
	() => import('@/app/_components/SendEmailModal'),
	{
		ssr: false,
	}
);

const InstructionModal = dynamic(
	() => import('@/app/_components/Common/InstructionModal'),
	{
		ssr: false,
	}
);

const ListResults = dynamic(() => import('../../[locale]/home/ListResults'), {
	ssr: false,
});

const FirstTimeSetupModal = dynamic(
	() => import('@/app/_components/Common/FirstTimeSetupModal'),
	{
		ssr: false,
	}
);

const Snowfall = dynamic(() => import('@/app/_components/Effect/Snowfall'), {
	ssr: false,
});
const Fireworks = dynamic(() => import('@/app/_components/Effect/Fireworks'), {
	ssr: false,
});

function ModalConfig(props: any) {
	const { theme, state, handlers, setting, eventList, selectedEvent } = props;
	const modalTypes = useSelector(GeneralSelectors.selectModalTypes);

	return (
		<>
			{modalTypes.includes(ModalType.LIST_RESULT) && (
				<ModalResults
					setting={setting}
					prizeIndex={state.prizeIndex}
					onChange={handlers.ON_CHANGE_PRIZE_TAB}
				/>
			)}

			{modalTypes.includes(ModalType.LIST_EVENT) && (
				<ListEvent
					eventData={eventList}
					handlers={handlers}
					selectedEvent={selectedEvent}
					open={modalTypes.includes(ModalType.LIST_EVENT)}
				/>
			)}

			{modalTypes.includes(ModalType.SETTING) && <Setting />}

			{modalTypes.includes(ModalType.THEME) && <ThemeSetting />}

			{modalTypes.includes(ModalType.CREATE_EVENT) && <CreateEvent />}

			{modalTypes.includes(ModalType.LIST_OPTION) && <ListOption />}

			{modalTypes.includes(ModalType.CONTACT) && <ContactModal />}

			{modalTypes.includes(ModalType.SEND_EMAIL) && <SendEmailModal />}

			{modalTypes.includes(ModalType.GUIDE) && <GuideModal />}

			{modalTypes.includes(ModalType.INSTRUCT) && <InstructionModal />}

			{modalTypes.includes(ModalType.FIRST_TIME_SETUP) && (
				<FirstTimeSetupModal />
			)}

			{theme?.key === '8' && <Snowfall />}
			{(theme?.key === '3' || theme?.key === '4') && <Fireworks />}
		</>
	);
}

export default memo(ModalConfig);
