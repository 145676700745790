import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import SmartLink from '@/app/_components/SmartLink';
import { ROUTERS } from '@/constant';
import { useI18n } from '@/locales/client';
import {
	AudioActions,
	AudioType,
	GlobalDispatch,
	ThemeSelectors,
} from '@/store';
import { GeneralActions, ModalType } from '@/store/General';

const Footer = ({ cls }: { cls?: string }) => {
	const { t } = useI18n();
	const theme = useSelector(ThemeSelectors.themeSelector);

	const onOpenContactModal = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(GeneralActions.openModalByType(ModalType.CONTACT));
	}, []);

	const onOpenSetting = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(GeneralActions.openModalByType(ModalType.SETTING));
	}, []);

	const onOpenListOption = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(GeneralActions.openModalByType(ModalType.LIST_OPTION));
	}, []);

	const onOpenGuideModal = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(GeneralActions.openModalByType(ModalType.GUIDE));
	}, []);

	const onShowResultModal = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(GeneralActions.openModalByType(ModalType.LIST_RESULT));
	}, []);

	return (
		<div className={`center gap-4 ${cls}`}>
			<button
				onClick={onOpenSetting}
				style={{ background: theme.bg_table_prize }}
				className={
					'col md:row hover:ring-gray-3/10 items-center rounded-full px-4 py-2 text-sm font-normal text-white duration-300 hover:shadow-inner hover:ring-1'
				}
				title={t('tooltip.setting') as string}
			>
				<i className={'c4c-setting mr-2 text-lg'} />
				<span className="">{t('tooltip.setting')}</span>
			</button>
			<button
				onClick={onOpenListOption}
				style={{ background: theme.bg_table_prize }}
				className={
					'col md:row hover:ring-gray-3/10 items-center rounded-full px-4 py-2 text-sm font-normal text-white duration-300 hover:shadow-inner hover:ring-1'
				}
				title={t('list.option_title') as string}
			>
				<i className={'c4c-setting mr-2 text-lg'} />
				<span className="">{t('list.option_title')}</span>
			</button>
			<button
				onClick={onOpenContactModal}
				style={{ background: theme.bg_table_prize }}
				className={
					'col md:row hover:ring-gray-3/10 items-center rounded-full px-4 py-2 text-sm font-normal text-white duration-300 hover:shadow-inner hover:ring-1'
				}
				title={t('footer.support') as string}
			>
				<i className={'c4c-sms-tracking mr-2 text-lg'} />
				<span className="">{t('footer.support')}</span>
			</button>
			<SmartLink
				href={ROUTERS.LICENSE}
				onClick={() =>
					GlobalDispatch(
						AudioActions.playAudioRequest(AudioType.CLICK)
					)
				}
				style={{ background: theme.bg_table_prize }}
				className={
					'col md:row hover:ring-gray-3/10 items-center rounded-full px-4 py-2 text-sm font-normal text-white duration-300 hover:shadow-inner hover:ring-1'
				}
			>
				<i className={'c4c-crown mr-2 text-lg'} />
				<span className="">{t('footer.upgrade')}</span>
			</SmartLink>
			{/* TODO: Chỉ show button này với chế độ xem đơn giản */}
			{/* <button
				onClick={onShowResultModal}
				style={{ background: theme.bg_table_prize }}
				className={
					'col md:row hover:ring-gray-3/10 items-center rounded-full px-4 py-2 text-sm font-normal text-white duration-300 hover:shadow-inner hover:ring-1'
				}
				title={t('Xem kết quả') as string}
			>
				<i className={'c4c-lamp-on mr-2 text-lg'} />
				<span className="">{t('Xem kết quả')}</span>
			</button> */}
			{/* <button
				onClick={onOpenGuideModal}
				style={{ background: theme.bg_table_prize }}
				className={
					'col md:row hover:ring-gray-3/10 items-center rounded-full px-4 py-2 text-sm font-normal text-white duration-300 hover:shadow-inner hover:ring-1'
				}
				title={t('footer.instruct') as string}
			>
				<i className={'c4c-lamp-on mr-2 text-lg'} />
				<span className="">{t('footer.instruct')}</span>
			</button> */}
		</div>
	);
};

export default memo(Footer);
